
import { MobileService } from '@/services/MobileService'
import { Component, Vue } from 'vue-property-decorator'
import CCLILogo from '../CCLILogo/CCLILogo.vue'
import ProfileDropdown from '../ProfileDropdown/ProfileDropdown.vue'

@Component({
  components: {
    CCLILogo,
    ProfileDropdown
  }
})
export default class SiteHeader extends Vue {
  onMobileClick() {
    MobileService.show();
  }
  get mobileNavShown() { 
    return MobileService.showMobileNav
  }

  get isDev() { 
    return process.env.NODE_ENV === 'development' || (window as any).enableDebug 
  }
}
