
import { ExpandableDownloadableItem } from '@/models/frontendOnly/ExpandableDownloadableItem';
import { Component, Vue } from 'vue-property-decorator'
import { ApiService } from './ApiService';
import { asyncDownload } from '@/functions/DownloadFunctions';
import router from '@/router';
import { SiteBannerService } from './SiteBannerService';
import { bus, i18n } from '@/main';

@Component
class DownloadServiceClass extends Vue {

  downloadCounts: number | null=  null;

  async getDownload( obscuredPath: string, fileName: string, id: string){ 
    asyncDownload(`/api/DownloadFile?key=${obscuredPath}&id=${id}`, fileName)
  }

  async getZipFileContents(item: ExpandableDownloadableItem){
    if (!item.expanded){ 
      return;
    }

    if (item.contentsError){ 
      item.contentsError = false;
    }

    if (!item.contents){
      try{ 
        item.contents = await ApiService.post('/api/GetS3FileContents',{ path: item.path})
      }catch(err){ 
        item.contentsError = true;
        console.error(err);
      }
    }
  }

  async init(){ 
    await this.getNewDownloadCount();
    setInterval(this.getNewDownloadCount, 5000)
  }

  async getNewDownloadCount(){ 
    const lastCount = this.downloadCounts;
    this.downloadCounts = await ApiService.post('/api/getNewDownloadCount', {}, true)
    if (lastCount !== null && this.downloadCounts !== null && this.downloadCounts > lastCount){
      bus.$emit('newDownloadsAvailable');
      SiteBannerService.show({
        message: i18n.t('R.MES_FilesReadyForDownload').toString(),
        actionLabel: i18n.t('R.LIT_GoToDownloads').toString(),
        onAction: () => { 
          router.push('/downloads')
        }
      })
    }
  }
  
}

export const DownloadService = new DownloadServiceClass()
