import { bus, i18n } from '@/main';
import { Language } from '@/models/Language';
import { Component, Vue } from 'vue-property-decorator'
import { ApiService } from './ApiService';
import { LocalStorageService } from './LocalStorageService';
import { ProfileService } from './ProfileService';

@Component
class TranslationServiceClass extends Vue {

  loadedLanguages: string[] = [];
  availableLanguages: Language[] = [];
  language = '';

  async getAvailableLanguages() {
    this.availableLanguages = await ApiService.get('/api/languages');
  }  

  setI18nLanguage (lang: string) {
    i18n.locale = lang;
    if (document){
      const html = document.querySelector('html');
      if (html){
        html.setAttribute('lang', lang);
      }
    }
    
    return lang;
  }

  async getLanguage(lang: string){ 
    if (this.loadedLanguages.includes(lang)) {
      return;
    }
    const response = await ApiService.get(`/api/lang/${lang}`);
    i18n.setLocaleMessage(lang, response.messages[lang]);
  }
  
  async setLanguage(lang: string) {
      
    this.language = lang;
    LocalStorageService.setItem('language', lang);

    if (i18n.locale === lang) {
      this.setI18nLanguage(lang);
      return;
    }
  
    await this.getLanguage(lang);   
    this.setI18nLanguage(lang);
  }


  async init(){ 
    await TranslationService.getAvailableLanguages();
    const localLang = LocalStorageService.getItem('language') || ProfileService.profile?.language || 'en-US';
    await TranslationService.setLanguage(localLang);
  }
}

export const TranslationService = new TranslationServiceClass()
