import { Collapse } from 'bootstrap';
import { Component, Vue } from 'vue-property-decorator'

@Component
class SiteBannerServiceClass extends Vue {
  shown = false;
  message = '';
  actionLabel = '';
  
  onAction: (() => void) | null = () => {};
  ref: Collapse | null = null;

  init(ref: Collapse){ 
    this.ref = ref;
  }

  show(data: { message: string; actionLabel: string; onAction: () => void }){
    this.message = data.message
    this.actionLabel = data.actionLabel
    this.onAction = data.onAction
    this.shown = true
    this.ref?.show()
  }

  hide(){ 
    this.shown = false
    this.ref?.hide()
  }
}

export const SiteBannerService = new SiteBannerServiceClass()
