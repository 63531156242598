import { Component, Vue } from 'vue-property-decorator'

@Component
class MobileServiceClass extends Vue {
  showMobileNav = false;
  
  show(){ 
    this.showMobileNav = true;
  }

  hide(){ 
    this.showMobileNav = false;
  }
}

export const MobileService = new MobileServiceClass()
