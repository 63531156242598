import shortUUID from 'short-uuid'
import { Component, Vue } from 'vue-property-decorator'
import { Toast } from '@/models/Toast'

@Component
class ToastServiceClass extends Vue {
  toasts: Toast[] = [];

  createToast (data: { variant: 'success' | 'danger', message: string })  {
    const id = shortUUID.generate()
    const toast: Toast = {
      variant: data.variant,
      message: data.message,
      id: id,
      show: true
    }
    this.toasts.push(toast)
    setTimeout(() => {
      this.hideToast(id)
    }, 3000)
  }

  hideToast (id: string)  {
    const toast = this.toasts.find(t => t.id === id)
    if (!toast) {
      return
    }
    toast.show = false
    setTimeout(() => {
      const index = this.toasts.findIndex(t => t.id === id)
      if (index) {
        this.toasts.splice(index, 1)
      }
    })
  }

  successToast(message: string){ 
    this.createToast({variant: 'success', message})
  }
  dangerToast(message: string){ 
    this.createToast({variant: 'danger', message})
  }
}

export const ToastService = new ToastServiceClass()
