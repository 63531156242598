
import { Component, Prop, Vue } from 'vue-property-decorator'
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'
import { MobileService } from '@/services/MobileService';

@Component({ 
  components:{ 
    SvgIcon
  }
})
export default class SiteNavItem extends Vue {
  @Prop({ default: null })
  icon!: string | null;

  @Prop({ default: null })
  svgIcon!: string[] | null;

  @Prop({ default: false, type: Boolean })
  active!: boolean;

  @Prop({ default: '' })
  href!: string;

  @Prop({ default: '' })
  to!: string;

  @Prop({ default: false, type: Boolean })
  exact!: boolean;

  @Prop({ default: '' })
  target!: string;
  

  hideMobileNav() {
    MobileService.hide();
  }
}
