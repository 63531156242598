
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { SelectOption } from '@/models/SelectOption'
import { uniqueId } from 'lodash';
import HelpIcon from '@/components/HelpIcon/HelpIcon.vue'

@Component({
  components: {
    HelpIcon
  }
})
export default class FSelect extends Vue {
  @Prop({ default: () => ([]) })
  options!: SelectOption[];

  @Prop({ default: '' })
  label!: string;

  @Prop({ default: '' })
  size!: string;

  @Prop({ default: false, type: Boolean })
  required!: boolean;

  @Prop({ default: false, type: Boolean })
  hiddenLabel!: boolean;

  @Prop({ default: '' })
  helpTitle!: string;

  @Prop({ default: () => 'input'+uniqueId()})
  id!: string;


  @Prop({ default: '' })
  helpText!: string;

  get classes () : string {
    return this.size ? `form-select-${this.size}` : ''
  }

  @Prop()
  value !: string;

  get val() {
    return this.value;
  }

  set val(value){
    this.$emit('input', value);
  }

    error = '';
  mounted(){
    this.onInput();
  }

  @Watch('val')
  onInput(){
    this.error = (this.$refs.input as HTMLInputElement).validationMessage;
  }


}
