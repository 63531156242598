
import { Component, Vue } from 'vue-property-decorator'

@Component
class BusyServiceClass extends Vue {
  busy = false;

  showBusy(){ 
    this.busy = true;
    // console.trace('tracing show busy');
  }

  hideBusy() { 
    this.busy = false;
    // console.trace('tracing hide busy');
  }
}

export const BusyService = new BusyServiceClass()
