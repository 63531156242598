
import shortUUID from 'short-uuid'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FCheckbox extends Vue {

  @Prop({ default: () => shortUUID.generate() })
  id !: string;

  @Prop({default: false, type: Boolean})
  value!: boolean;

  @Prop({default: false, type: Boolean})
  disabled!: boolean;

  @Prop({default: false, type: Boolean})
  required!: boolean;

  get val(){ 
    return this.value;
  }

  set val(value) { 
    this.$emit('input', value);
  }
}
