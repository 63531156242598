
import { Component, Vue } from 'vue-property-decorator'
import { ApiService } from './ApiService';
import { ProfileService } from './ProfileService';

@Component
class PaymentSettingsServiceClass extends Vue {
  directDepositStatus: 'PROCESSING' | 'NOT-YET-SETUP' | 'SETUP-COMPLETED' | '' = '';

  async getDirectDepositStatus() { 
    if (!ProfileService.profile){ 
      return;
    }
    this.directDepositStatus = await ApiService.post('/api/GetDirectDepositStatus', { 
      organizationId: ProfileService.profile.org.id 
    }) as 'PROCESSING' | 'NOT-YET-SETUP' | 'SETUP-COMPLETED';
  }
  
}

export const PaymentSettingsService = new PaymentSettingsServiceClass()
