const formatDate = (value: string) => { 
  if (!value){ 
    return '';
  }

  const date = new Date(value);
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const day = date.getUTCDate();
  const monthString = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov', 'Dec'];
  return `${day} ${monthString[month]} ${year}`;
};

export default {
  formatDate
}