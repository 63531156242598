import { SelectOption } from '@/models/SelectOption';
import { Territory } from '@/models/Territory';
import { Component, Vue } from 'vue-property-decorator'
import { ApiService } from './ApiService';

@Component
class TerritoryServiceClass extends Vue {
  availableTerritories: SelectOption[] = [];

  intTerritoryId: string | null = null;

  set territoryId(value: string){ 
    this.intTerritoryId = value;
    this.$cookies.set('territorySelection', value);
  }

  get territoryId(): string { 
    return this.intTerritoryId || '1';
  } 

  initTerritory(value: string){ 
    this.intTerritoryId = value;
  }

  init(){     
    const initTerritoryId = this.$cookies.get('territorySelection');

    if (initTerritoryId){
      this.territoryId = initTerritoryId;
    }
  }

  async getAvailableTerritories(){ 
    if (this.availableTerritories.length){
      return;
    }

    const response = await ApiService.post('/api/GetTerritories') as Territory[];
    this.availableTerritories = response.map(t => ({
      value: t.territoryId?.toString() || '', 
      label: t.territoryName,
    }))

    this.availableTerritories.sort((a,b) => { 
      if (a.label.toLowerCase() > b.label.toLowerCase()){ 
        return 1;
      }else if (a.label < b.label){ 
        return -1;
      }else{ 
        return 0;
      }
    });
  }
}

export const TerritoryService = new TerritoryServiceClass()
