import { CustomError } from "@/models/CustomError";
import Vue from "vue";

declare const rg4js: any;

class RayGunServiceClass {
  handleVueError(err: Error | CustomError, vm: Vue, info: string) {
    console.error(err);
    if ((err as CustomError).ignoreError){
      return;
    }
    rg4js('send', {
      error: err,
      customData: {err, info}
    });
  }
}

export const RayGunService = new RayGunServiceClass();
