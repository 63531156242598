import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import VueI18n from 'vue-i18n'
import VueCookies from 'vue-cookies'


import { TranslationService } from '@/services/TranslationService'
import { ProfileService } from './services/ProfileService'
import { ApiService } from './services/ApiService'
import { PaymentSettingsService } from './services/PaymentSettingsService'

import './scss/app.scss'
import 'bootstrap'
import { RayGunService } from './services/RayGunService'
import DateFunctions from './functions/DateFunctions';
import { DownloadService } from './services/DownloadService'
import { DynamicTranslationService } from './services/DynamicTranslationService'
import { TerritoryService } from './services/TerritoryService'

Vue.config.productionTip = false

Vue.config.errorHandler = RayGunService.handleVueError;

Vue.use(VueMeta);
Vue.use(VueCookies);

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'default',
  messages: {}
});


Vue.filter('formatDate', DateFunctions.formatDate)

export const bus = new Vue();

(async() => { 
  await ApiService.getAntiForgeryToken();
  await ProfileService.init();
  await TranslationService.init();
  TerritoryService.init();
  await DownloadService.init();
  if (ProfileService.profile && ProfileService.profile.authorized){
    await PaymentSettingsService.getDirectDepositStatus()
  }
  
  new Vue({
    router,
    i18n,
    render: h => h(App), 
    metaInfo: () => {
      const title = DynamicTranslationService.titleName;
      return { 
        title: title,
        titleTemplate: `%s - ${title}`
      }
    }
  }).$mount('#app')
})();