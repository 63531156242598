
import { i18n } from '@/main';
import { Component, Vue } from 'vue-property-decorator'

@Component
class DynamicTranslationServiceClass extends Vue {
    get titleName(){
        return `CCLI ${i18n.t('G.LIT_AppName_RightsPortal').toString()}`;
    }

    get homeTitle(){
        return i18n.t('G.LIT_Home').toString();
    }

    get agreementsTitle() {
        return i18n.t('G.LIT_Agreements').toString();
    }

    get contactTitle(){
        return i18n.t('G.LIT_Contact').toString();
    }

    get downloadsTitle(){
        return i18n.t('R.LIT_Downloads').toString();
    }

    get catalogDetailsTitle(){
        return i18n.t('R.LIT_CatalogDetails').toString();
    }

    get viewCatalogsTitle(){
        return i18n.t('R.LIT_ViewCatalogs').toString();
    }

    get songDetailsTitle(){
        return i18n.t('R.LIT_SongDetails').toString();
    }

    get searchAllCatalogsTitle(){
        return i18n.t('R.LIT_SearchAllCatalogs').toString();
    }

    get registerSongsTitle(){
        return i18n.t('R.LIT_RegisterSongs').toString();
    }

    get searchAllSongsTitle(){
        return i18n.t('R.LIT_SearchAllSongs').toString();
    }

    get paymentSettingsTitle(){
        return i18n.t('R.LIT_PaymentSettings').toString();
    }

    get payoutDocumentsTitle(){
        return i18n.t('R.LIT_PayoutDocuments').toString();
    }

    get lyricTypeBlankHeaderText(){
        return i18n.t('R.LIT_LyricTypeBlankHeader').toString();
    }
    get lyricTypeBridgeText(){
        return i18n.t('R.LIT_LyricTypeBridge').toString();
    }
    get lyricTypeChorusText(){
        return i18n.t('R.LIT_LyricTypeChorus').toString();
    }
    get lyricTypeDescantText(){
        return i18n.t('R.LIT_LyricTypeDescant').toString();
    }
    get lyricTypeEndingText(){
        return i18n.t('R.LIT_LyricTypeEnding').toString();
    }
    get lyricTypeInterludeText(){
        return i18n.t('R.LIT_LyricTypeInterlude').toString();
    }
    get lyricTypeIntroText(){
        return i18n.t('R.LIT_LyricTypeIntro').toString();
    }
    get lyricTypeMidSectionText(){
        return i18n.t('R.LIT_LyricTypeMidSection').toString();
    }
    get lyricTypeOstinatoText(){
        return i18n.t('R.LIT_LyricTypeOstinato').toString();
    }
    get lyricTypePostChorusText(){
        return i18n.t('R.LIT_LyricTypePostChorus').toString();
    }
    get lyricTypePreChorusText(){
        return i18n.t('R.LIT_LyricTypePreChorus').toString();
    }
    get lyricTypeRapText(){
        return i18n.t('R.LIT_LyricTypeRap').toString();
    }
    get lyricTypeRefrainText(){
        return i18n.t('R.LIT_LyricTypeRefrain').toString();
    }
    get lyricTypeSpokenWordsText(){
        return i18n.t('R.LIT_LyricTypeSpokenWords').toString();
    }
    get lyricTypeTagText(){
        return i18n.t('R.LIT_LyricTypeTag').toString();
    }
    get lyricTypeVampText(){
        return i18n.t('R.LIT_LyricTypeVamp').toString();
    }
    get lyricTypeVerseText(){
        return i18n.t('R.LIT_LyricTypeVerse').toString();
    }
    get registerSongButtonText(){
        return i18n.t('R.LIT_RegisterSongButton').toString();
    }

    get addChordsText() {
      return i18n.t('R.LIT_AddChords').toString();
    }
    get editChordsText() {
      return i18n.t('R.LIT_EditChords').toString();
    }

    get addLyricsText() {
        return i18n.t('R.LIT_AddLyrics').toString();
    }
    get editLyricsText() {
        return i18n.t('R.LIT_EditLyrics').toString();
    }
    get editSongDetailsText() {
        return i18n.t('R.LIT_EditSongDetails').toString();
    }
    get registerEditSongOwnershipText() {
        return i18n.t('R.LIT_RegisterEditSongOwnership').toString();
    }
    get savedText() {
        return i18n.t('R.LIT_Saved').toString();
    }
    get needsReviewText() {
        return i18n.t('R.LIT_NeedsReview').toString();
    }
    get recentInReviewText() {
        return i18n.t('R.LIT_RecentInReview').toString();
    }
    get successText() {
        return i18n.t('R.LIT_Success').toString();
    }
    get unableToProcessText() {
        return i18n.t('R.LIT_UnableToProcess').toString();
    }

    get serverErrorText() {
        return i18n.t('R.MES_ServerError').toString();
    }
}

export const DynamicTranslationService = new DynamicTranslationServiceClass()
