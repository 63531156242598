
import { Component, Vue } from 'vue-property-decorator'
import Modal from "./Modal.vue";
import ConfirmService from '@/services/ConfirmService';

@Component({ 
  components: { 
    Modal
  }
})
export default class ConfirmModal extends Vue {
  store = ConfirmService;

  mounted(){ 
    this.store.attachRef(this.$refs.modal as Modal);
  }

  onOk(){ 
    (this.$refs.modal as Modal).hide();
    this.store.resolve(true);
  }

  onCancel(){ 
    (this.$refs.modal as Modal).hide();
    this.store.resolve(false);
  }
}
