import { BusyService } from "@/services/BusyService";
import { DynamicTranslationService } from "@/services/DynamicTranslationService";
import { ToastService } from "@/services/ToastService";
import axios from "axios";

export async function asyncDownload(downloadUrl: string, suggestedFilename: string) {
  BusyService.showBusy();
  try{
    const response = await axios.get(downloadUrl, {responseType: 'blob'});
    if (!response){
      throw new Error('Error in resposne');
    }
    const contentDisposition = response.headers['content-disposition'];

    if (!contentDisposition){
      throw new Error('Error in resposne');
    }

    const contentDispositionSplit = contentDisposition.split('; ');

    let filename = '';
    let filenameText = contentDispositionSplit.find((h: string) => h.includes('filename="')) ;

    if (filenameText){
      filenameText = filenameText.trim();
      const matched = filenameText.match(/^filename="(.*)"$/)
      if (matched && matched[1]){
        filename = matched[1];
      }
    }

    if (!filename){
      filename = suggestedFilename;
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    BusyService.hideBusy();
  }catch(err){
    ToastService.dangerToast(DynamicTranslationService.serverErrorText)
    console.error(err);
    BusyService.hideBusy();
    throw new Error('Download cannot be completed');
  }
}
