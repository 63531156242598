
import { Component, Vue } from 'vue-property-decorator'
import Modal from './Modal.vue';
import { SiteModalService } from '@/services/SiteModalService'

@Component({ 
  components:{ 
    Modal
  }
})
export default class SiteModalMessage extends Vue {
  store = SiteModalService;
  
  mounted() { 
    this.store.initRef(this.$refs.modal as Modal);
  }

  onOk() { 
    (this.$refs.modal as Modal).hide();
  }
}
