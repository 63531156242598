
import { Component, Vue } from 'vue-property-decorator'
import ContactContent from './ContactContent.vue'
import Modal from '@/components/Modal/Modal.vue';
import { bus } from '@/main';

@Component({
  components: {
    ContactContent, 
    Modal
  },
})
export default class ContactModal extends Vue {
  mounted(){ 
    bus.$on('showContactInfo', this.show);
  }
  beforeDestroy() { 
    bus.$off('showContactInfo', this.show);
  }
  show() { 
    (this.$refs.modal as Modal).show();
  }
}
