import { i18n } from '@/main';
import { CustomError } from '@/models/CustomError';
import axios, { AxiosError } from 'axios';
import { Component, Vue } from 'vue-property-decorator'
import { BusyService } from './BusyService';
import { ToastService } from './ToastService';
import { TranslationService } from './TranslationService';

let initPromise: Promise<void> | null = null;
declare const window: any;
const baseUrl = process.env.VUE_APP_API_BASE_URL || '';
declare const rg4js: any;

@Component
class ApiServiceClass extends Vue {

  antiForgeryToken = '';
  get config() {
    return {
      headers: {
        "RequestVerificationToken": this.antiForgeryToken,
        "client-locale": TranslationService.language
      }
    }
  }

  getFormData(object: any) {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
  }


  handleResponse<T>(path: string, requestParams: any, response: any, noErrorToasts: boolean){
    if (response?.data?.isSuccess === false){
      BusyService.hideBusy();
      if (response.data.publicStatusMessages && response.data.publicStatusMessages.length){
        if (!noErrorToasts){
          response.data.publicStatusMessages.forEach( (m: string) => ToastService.dangerToast(m))
        }
      }else{
        if (!noErrorToasts){
          ToastService.dangerToast(i18n.t('R.MES_ServerError').toString())
        }
        throw new Error('Missing Public Status Messages');
      }

      if (process.env.NODE_ENV === 'development' || window.debug){
        console.log('API Response FAILED', path);
      }
      console.error(response)
      throw response
    }else if (!response) {
      BusyService.hideBusy();
      console.log('API Response', path, requestParams, null);
      throw new Error('Missing Response');
    }

    let payload = response.data.payload;

    try {
      payload = JSON.parse(payload);
    }catch(err){}

    if (process.env.NODE_ENV === 'development' || window.debug){
      console.log('API Response', path, requestParams, response.data, payload);
    }

    return payload as T;
  }

  handleServerError(err: AxiosError, path: string, noErrorToasts: boolean){
    BusyService.hideBusy();

    if (process.env.NODE_ENV === 'development' || window.debug){
      console.log('API Response FAILED', path);
    }

    if (!err.response?.status){
      if (!noErrorToasts){
        ToastService.dangerToast(i18n.t('R.MES_ServerError').toString())
      }
      rg4js('send', {
        error: err,
        customData: {err, comment: 'status missing'}
      });
      throw new CustomError(err, false, `No Status Code Preovided ${path}`);
    } else {
      switch(err.response.status) {
        //unauthenticated - redirect to signin
        case 401:
          window.location.href =  process.env.VUE_APP_SIGN_IN_URL+encodeURIComponent(window.location.href);
          throw new CustomError(err, true, `Unathenticated - redirect to signin: ${path}`);

        //conflict - force refresh (maybe there's a better response status code to use)
        case 409:
          window.location.reload();
          throw new CustomError(err, true, `Forcing page to refresh: ${path}`);

        default:
          if (!noErrorToasts){
            ToastService.dangerToast(i18n.t('R.MES_ServerError').toString());
          }

          rg4js('send', {
            error: err,
            customData: {err, comment: 'status unrecognized'}
          });

          throw new CustomError(err, false, `Request Error (${err.response.status}): ${path}`);

      }
    }

  }

  async get(
    path: string,
    ignoreHandlingResponse = false
  ) {
    const url = `${baseUrl}${path}`;
    let response: any;

    try{
      response = await axios.get(url);
    }catch(err){
      this.handleServerError(err as AxiosError, url, false);
    }
    if (ignoreHandlingResponse){
      return response.data;
    }else{
      return this.handleResponse(url, null, response, false);
    }
  }

  async post<T>(path: string, params?:{[key: string]: any}, noErrorToasts = false){
    const url = `${baseUrl}${path}`;
    let response: any;
    let requestParams: {[key: string]: any} | undefined = params;

    if (!requestParams){
      requestParams = {};
    } else {
      //clense data from reactive properties
      requestParams = JSON.parse(JSON.stringify(requestParams));
    }

    //wait until app has fully init before proceeding
    try{
      await initPromise;
    }catch(err){
      BusyService.hideBusy();
      if (!noErrorToasts){
        ToastService.dangerToast(i18n.t('R.MES_ServerError').toString())
      }
      console.error(err)
      throw err;
    }

    try{
        response = await axios.post(
          url,
          this.getFormData(requestParams),
          this.config
        );
    }catch(err){
      this.handleServerError(err as AxiosError, url, noErrorToasts);
    }
    return this.handleResponse<T>(url, requestParams, response, noErrorToasts);
  }

  getAntiForgeryToken() {
    initPromise = this.get('/api/tools/GetAntiForgery').then(token => this.antiForgeryToken = token);
    return initPromise;
  }


}

export const ApiService = new ApiServiceClass()
