import Modal from '@/components/Modal/Modal.vue';
import { i18n } from '@/main';
import { Component, Vue } from 'vue-property-decorator'

@Component
class SiteModalServiceClass extends Vue {
  message = '';
  title = '';
  okTitle = '';
  size='';
  ref: Modal | null = null;
  
  showModalMessage(data:{ title: string; message: string; okTitle?: string; size?: string}) {
    if (!this.ref){ 
      return;
    }
    this.message = data.message;
    this.title = data.title;
    this.okTitle = data.okTitle || i18n.t('G.LIT_Okay').toString();
    this.size = data.size || '';
    (this.ref as Modal).show();
  }

  initRef(ref: Modal){
    this.ref = ref;
  } 
}

export const SiteModalService = new SiteModalServiceClass()
