import { i18n } from '@/main';
import { Profile } from '@/models/Profile'
import router from '@/router';
import { Component, Vue } from 'vue-property-decorator'
import { ApiService } from './ApiService';
import { SiteModalService } from './SiteModalService';
import { TerritoryService } from './TerritoryService';

const POLL_IDLETIME_SECS = 5;
const IDLE_TIMEOUT_MINS = parseInt(process.env.VUE_APP_IDLE_TIMEOUT_MINS || '60');
declare const rg4js: any;

@Component
class ProfileServiceClass extends Vue {

  profile: Profile | null = null;
  lastActionTimestamp = this.getCurrentTimestamp();
  intervalRef = 0;
  idleWarningShown = false;

  getCurrentTimestamp(){
    return new Date().getTime()/1000;
  }

  async init(){
    await this.getProfile();
    this.setupIdleTimeCheck();
  }

  setupIdleTimeCheck(){
    if (IDLE_TIMEOUT_MINS < 0){
      return;
    }
    this.intervalRef = setInterval(() => {
      const idleTimeSeconds = (this.getCurrentTimestamp() - this.lastActionTimestamp);
      //show warning modal
      if (idleTimeSeconds > (60 * (IDLE_TIMEOUT_MINS - 1))&&
        !this.idleWarningShown
      ){
        this.idleWarningShown = true;
        SiteModalService.showModalMessage({
          title: i18n.t('R.LIT_SignOutWarning').toString(),
          message: i18n.t('R.MES_SignOutWarning').toString(),
          okTitle: i18n.t('R.LIT_StaySignedIn').toString(),
        });
      }

      if (idleTimeSeconds > 60 * IDLE_TIMEOUT_MINS &&
        this.profile?.signOutUrl
      ){
        location.href = this.profile.signOutUrl
        clearInterval(this.intervalRef);
        this.intervalRef = 0;
      }
    }, POLL_IDLETIME_SECS*1000);
    document.addEventListener('mousedown', this.resetIdleTime);
    document.addEventListener('keydown', this.resetIdleTime);
  }

  beforeDestory(){
    if (IDLE_TIMEOUT_MINS < 0){
      return;
    }
    document.removeEventListener('mousedown', this.resetIdleTime);
    document.removeEventListener('keydown', this.resetIdleTime);
  }

  resetIdleTime(){
    this.lastActionTimestamp = this.getCurrentTimestamp();
    this.idleWarningShown = false;
  }

  get fullName() {
    if (!this.profile){
      return '';
    }
    return `${this.profile.user.fullName}`
  }

  async getProfile() {
    this.profile = await ApiService.get('/api/user/profile');

    if (this.profile){
      rg4js('withCustomData', {
        OrganizationId: this.profile.org.id,
        PersonId: this.profile.user.id,
      });

      // @ts-ignore
      rg4js('setUser', {
        identifier: this.profile.user.id,
        isAnonymouse: false,
        uuid: this.profile.user.id
      });

      TerritoryService.initTerritory(this.profile.org.defaultTerritory);

      if (!this.profile.authorized){
        router.push('/unavailable');
      }else if (this.profile.authorized && router.currentRoute.name === 'Unavailable'){
        router.push('/');
      }
    }else{
      router.push('/unavailable');
    }
  }

  get enableDirectDeposit(){
    return this.profile &&  this.profile.featureTogglePayments
  }
}

export const ProfileService = new ProfileServiceClass()
