
import { Component, Vue } from 'vue-property-decorator'
import SiteHeader from '@/components/SiteHeader/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter/SiteFooter.vue'
import SiteBanner from '@/components/SiteBanner/SiteBanner.vue'
import SiteNav from '@/components/SiteNav/SiteNav.vue'
import Toasts from '@/components/Toast/Toasts.vue'
import SvgSprite from '@/components/SvgIcon/SvgSprite.vue'
import SiteModalMessage from '@/components/Modal/SiteModalMessage.vue'
import BusyOverlay from '@/components/BusyOverlay/BusyOverlay.vue'
import DirectDepositNoticeModal from '@/views/PaymentSettings/DirectDepositNoticeModal.vue';
import ConfirmModal from '@/components/Modal/ConfirmModal.vue';
import ContactModal from '@/views/Contact/ContactModal.vue';
import { ProfileService } from './services/ProfileService'
import { TranslationService } from './services/TranslationService'

@Component({
  components: {
    ConfirmModal,
    DirectDepositNoticeModal,
    SiteBanner,
    SiteHeader,
    SiteFooter,
    SiteNav,
    Toasts,
    SvgSprite,
    SiteModalMessage, 
    BusyOverlay, 
    ContactModal
  }
})
export default class App extends Vue {

  profileService = ProfileService;
  translationService = TranslationService;
}
