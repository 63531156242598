import { Component, Vue } from 'vue-property-decorator'

/**
 * This point of this service is used to help ensure that a single modal is evern shown at one time even if there are those called/nested to be above one another.
 */

@Component
class ModalServiceClass extends Vue {

  modalStack: {
    id: string,
    allowShownAsNested: boolean,
    setVisible: (viisible: boolean) => void
  } [] = [];

  onOpen(id: string, setVisibleFn: (visible:boolean) => void, allowShownAsNested = false){
    const alreadyIncludedIndex = this.modalStack.findIndex(m => m.id === id);
    if (alreadyIncludedIndex >= 0){
      this.modalStack.splice(alreadyIncludedIndex, 1);
    }
    if (this.modalStack.length && !this.modalStack[this.modalStack.length - 1].allowShownAsNested){
      this.modalStack[this.modalStack.length - 1].setVisible(false);
    }

    this.modalStack.push({
      id,
      allowShownAsNested,
      setVisible: setVisibleFn
    });
    return this.modalStack.length-1;
  }

  onClose(id: string){
    const index = this.modalStack.findIndex(m => m.id === id)
    if (index < 0 ){
      return;
    }

    this.modalStack.splice(index, 1);

    if (this.modalStack.length){
      if (!this.modalStack[this.modalStack.length - 1].allowShownAsNested){
        this.modalStack[this.modalStack.length - 1].setVisible(true);
      }
    }else{
     //  document.querySelector('.modal-backdrop.show')?.remove();
    }

  }
}

export const ModalService = new ModalServiceClass()
