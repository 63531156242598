import Modal from '@/components/Modal/Modal.vue';
import { Component, Vue } from 'vue-property-decorator'

@Component  
class ConfirmService extends Vue { 

  message = '';
  title = '';
  okLabel = '';
  cancelLabel = '';
  ref: Modal | null = null;
  opened = false;
  success = false;


  async showConfirm(data: {
    message: string;
    title: string;
    okLabel: string;
    cancelLabel: string
  }){ 
    if (!this.ref){ 
      return;
    }
    this.message = data.message;
    this.title = data.title;
    this.okLabel = data.okLabel;
    this.cancelLabel = data.cancelLabel;
    this.ref.show();
    this.opened = true;
    while (this.opened){ 
      await new Promise<void>((res) => setTimeout(() => res(), 250))
    }
    return this.success;
  }

  attachRef(modal: Modal){ 
    this.ref = modal;
  }

  resolve(success: boolean){ 
    this.opened = false;
    this.success = success;
  }
}

export default new ConfirmService();