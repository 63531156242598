class LocalStorageServiceClass {

  localStorageAvailable = false;

  constructor() { 
    this.getLocalStorageAvailable();
  }

  getLocalStorageAvailable() { 
    try {
      const storage = window.localStorage; 
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      this.localStorageAvailable = true;
    }
    catch(e) {
      this.localStorageAvailable = false;
    }
  }

  setItem(key: string, value: string){
    if (this.localStorageAvailable){
      localStorage.setItem(key, value);
    }
  }

  getItem(key: string){
    if (this.localStorageAvailable){
      return localStorage.getItem(key);
    }else{ 
      return null;
    }
  }

  removeItem(key: string) { 
    if (this.localStorageAvailable){
      localStorage.removeItem(key);
    }
  }
}

export const LocalStorageService = new LocalStorageServiceClass();